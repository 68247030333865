import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Gurukul = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
       
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Modernized Gurukul" activePage="Modernized Gurukul" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/01.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/02.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/03.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/04.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/05.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/06.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/07.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/08.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/09.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/10.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/11.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/12.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/13.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/14.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/15.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/16.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/17.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/ModernizedGurukul/18.webp' />
                            </SwiperSlide>

                           
                            
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                   
                                    <li>
                                        <span className="data"> Plot Area:</span> <span className="value">66,061 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value">  Ramoji Farm, Morbi</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">  Smit Mehta </span>
                                    </li>
                                    <li>
                                        <span className="data">Text:</span> <span className="value">  Neenu Paulose </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Modernized Gurukul - Pre School</h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    A space as colorful as the minds of its users.<br></br><br></br>

                                    The main concept behind this design was to integrate the renowned Indian Education system of the Guru Kul with a modern and integrated approach towards primary education. The structure was required to be fabricated as mobility of the structure was also of a major concern. The structure can be dismantled and rebuilt elsewhere as required. Each classroom acts as an individual entity in the form of blocks. The blocks have been placed in the Centre of the site in a circular fashion thereby making the central Amphitheatre the focal point. Bright and contrasting colors have been used on each and every block in the site which adds a whimsical feeling.<br></br><br></br>

                                    Circulation in the site is placed in a concentric fashion. The passage created around the central- amphitheater acts as the main circulation pathway and can be used by the students as a cycling track as well. The subtle tone acts in such a manner so as to not depose the overall cohesiveness of the blocks and other features.<br></br><br></br>
                                    Various tactile elements have also been introduced in the form of sand pits, swimming pool and landscaping. The uses of barricades are kept to a minimum in these spaces so that students can feel a sense of cohesiveness and openness during playtimes and they can have unrestricted access to all areas. A fountain has been placed at the entrance of the site that acts as a calming and serene element and adds to the tactile experience in the site. Each and every constituting element is placed so as to induce maximum functionality and style. The project was completed in 4 months.

                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
           
</div>
  )
}

export default Gurukul 
