import $ from 'jquery';
import React, { useEffect } from 'react';
import SectionGridLines from '../../CommonPages/SectionGridLines';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const totop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    useEffect(() => {
        // Totop Button
        // $('.totop a').on('click', function (e) {
        //     e.preventDefault();
        //     $('html, body').animate({ scrollTop: 0 }, '300');

        // });


        // Hide header on scroll down
        const nav = document.querySelector(".header");
        const scrollUp = "top-up";
        let lastScroll = 800;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 800) {
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
                return;
            }

            if (currentScroll > lastScroll) {
                // down
                nav.classList.add(scrollUp);
                $('.totop').addClass('show');

            } else if (currentScroll < lastScroll) {
                // up
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
            }
            lastScroll = currentScroll;
        });

    }, []);
    return (
        <>
            <footer className="footer box_padding">
                {/* <footer className="footer bg-dark-200 box_padding"> */}
                <div className="footer_inner bg-black">
                    <SectionGridLines />
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-4 col-md-2 col-sm-2">
                                <div className="section-header" >
                                    <h2>Contact</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="communication">
                                    <div className="info_body"
                                    >
                                        <h6>Our Ventures</h6>
                                        <a href="https://shreeshlockers.com/" target='_blank'><img src="images/locker-logo.png" alt="logo" style={{ maxWidth: '170px' }} /></a>
                                    </div>
                                    {/* <div className="info_body"
                                    >
                                        <h6>Studio Website</h6>
                                        <h5>www.shreeshdesignstudiollp.com</h5>
                                    </div> */}

                                    <div className="info_body"
                                    >
                                        <h6>Email Address</h6>
                                        <h5><a href="mailto:shreeshdesignstudio@gmail.com">shreeshdesignstudio@gmail.com</a> </h5>
                                    </div>
                                    <div className="info_body"
                                    >
                                        <h6>Phone No</h6>
                                        <h5><a href="tel:7802017131">4th Floor - +91 7802017131</a></h5>
                                        <h5><a href="tel:9723517131">5th Floor - +91 9723517131</a></h5>
                                    </div>
                                    <div className="info_body">
                                        <h6>Office Address</h6>
                                        <h5>"Shreesh" 4th - 5th Floor, Dr.Dastur Marg, Off Dr.Yagnik Road, B/h D.H. College, Rajkot - 360001, Gujrat, INDIA</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="footer_elements d-flex align-items-center justify-content-end">
                                    <div className="footer_elements_inner">
                                        {/* <div className="footer_logo my-4">
                                            <h6 style={{ color: "#a6a182", lineHeight: "2.125rem" }}>Our Ventures</h6>
                                            <a href="/" className="light_logo"><img src="images/locker-logo.png" alt="logo" style={{ maxWidth: '130px' }} /></a>
                                        </div> */}
                                        <div className="footer_logo"
                                        >
                                            <a href="/" className="light_logo"><img src="images/shreesh-white-logo.jpg" alt="logo" style={{ maxWidth: '130px' }} /></a>
                                            {/* <a href="/" className="light_logo"><img src="images/shreesh-logo.png" alt="logo" style={{ filter: 'invert(100%)',WebkitFilter: 'invert(100%)',maxWidth:'130px'}}/></a> */}
                                        </div>
                                        <div className="footer_social">
                                            <ul className="social_list">
                                                <li className="facebook"
                                                ><a href='https://www.facebook.com/shreeshdesignstudiollp/' target='_blank'><i className="bi bi-facebook"></i></a></li>
                                                {/* <li className="twitter"
                                                ><a><i className="bi bi-twitter"></i></a></li> */}
                                                <li className="instagram"
                                                ><a href='https://www.instagram.com/shreeshdesignstudiollp/' target='_blank'><i className="bi bi-instagram"></i></a></li>
                                                {/* <li className="youbetube"><a><i className="bi bi-youtube"></i></a></li> */}
                                            </ul>
                                        </div>
                                        {/* <div className="terms_condition">
                                            <ul>
                                                <li><a href='#'>Terms</a></li>
                                                <li><a href='#'>Condition</a></li>
                                                <li><a href='#'>Policy</a></li>
                                            </ul>
                                        </div> */}
                                        <div className="copyright"
                                        >
                                            <p style={{ zIndex: '9999' }}>ShreeshDesignStudio {currentYear}. All Rights Reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='position-absolute bottom-0' style={{ left: "1%" }}>
                        <img src="images/shreesh-short-waves.png" alt="" style={{ opacity: "0.6", filter: 'invert(30%)', WebkitFilter: 'invert(30%)', maxWidth: '230px' }} />
                    </div>
                </div>

            </footer >

            <div className="totop" onClick={totop}>
                <a href="#">UP</a>
            </div>
        </>
    );
};

export default Footer;