import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import SectionGridLines from '../CommonPages/SectionGridLines';

const Portfolio = () => {
    const ProjectData = [

        // ---------------ResidentialBunglow-------------
        {
            id: '1',
            title: 'Residential Bunglow',
            img: '/images/portfolio/packery/residentialBunglow/yamuna.webp',
            details: 'Yamuna Palace',
            tag: 'ResidentialBunglow',
            path: '/yamuna',

        },
        {
            id: '2',
            title: 'Residential Bunglow',
            img: '/images/portfolio/packery/residentialBunglow/RegalRetreat.webp',
            details: 'The Regal Retreat',
            tag: 'ResidentialBunglow',
            path: '/regal',

        },
        {
            id: '3',
            title: 'Residential Bunglow',
            img: '/images/portfolio/packery/residentialBunglow/SakariyaVilla.webp',
            details: 'Sakariya Villa',
            tag: 'ResidentialBunglow',
            path: '/sakariya',

        },
        {
            id: '4',
            title: 'Residential Bunglow',
            img: '/images/portfolio/packery/residentialBunglow/JetpurResidence.webp',
            details: 'Jetpur Residence',
            tag: 'ResidentialBunglow',
            path: '/jetpur',
        },
        {
            id: '5',
            title: 'Residential Bunglow',
            img: '/images/portfolio/packery/residentialBunglow/Bilipatra.webp',
            details: 'Billipatra',
            tag: 'ResidentialBunglow',
            path: '/billipatra',
        },
        // -----------Residential Apartment--------------
        {
            id: '6',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/SkyDiamond.webp',
            details: 'Sky Diamond',
            tag: 'ResidentialApartment',
            path: '/skydiamond',
        },
        {
            id: '7',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/SilverHeight.jpg',
            details: 'Silver Height',
            tag: 'ResidentialApartment',
            path: '/silver',
        },
        {
            id: '8',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/SiddhiHeritage2BHK.webp',
            details: 'Sidhdhi Heritage 2BHK',
            tag: 'ResidentialApartment',
            path: '/sidhdhi',
        },
        {
            id: '9',
            title: 'SiResidential Apartment',
            img: '/images/portfolio/packery/Apartment/SiddhiHeritage3BHK.webp',
            details: 'Sidhdhi Heritage 3BHK',
            tag: 'ResidentialApartment',
            path: '/sidhdhi3',
        },
        {
            id: '10',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/Serenity.webp',
            details: 'Serenity',
            tag: 'ResidentialApartment',
            path: '/serenity',
        },
        {
            id: '11',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/garden.webp',
            details: 'Garden City',
            tag: 'ResidentialApartment',
            path: '/garden',
        },
        {
            id: '12',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/jasani.webp',
            details: 'Dr. jasani Apartment',
            tag: 'ResidentialApartment',
            path: '/Jasani',
        },
        {
            id: '13',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/gor.webp',
            details: 'Dr. Gor Apartment',
            tag: 'ResidentialApartment',
            path: '/Gor',
        },
        {
            id: '14',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/diamond.webp',
            details: 'Diamond square',
            tag: 'ResidentialApartment',
            path: '/diamond',
        },
        {
            id: '15',
            title: 'Residential Apartment',
            img: '/images/portfolio/packery/Apartment/asopalav.webp',
            details: 'Asopalav',
            tag: 'ResidentialApartment',
            path: '/asopalav',
        },
        // ----------Hospitality Projects-----------
        {
            id: '16',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/grand.webp',
            details: 'The Grand Imperial Hotel',
            tag: 'HospitalityProjects',
            path: '/grandhotel',
        },

        {
            id: '17',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/Intothewild.webp',
            details: 'Into the Wild (Jungle theme restaurant)',
            tag: 'HospitalityProjects',
            path: '/intothewild',
        },
        {
            id: '18',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/LapinozVrundavan.webp',
            details: 'La Pinoz, Vrundavan outlet',
            tag: 'HospitalityProjects',
            path: '/lapinoz_vrundavan',
        },

        {
            id: '19',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/LapinozViranichowk.webp',
            details: 'La Pinoz, Virani Chowk outlet',
            tag: 'HospitalityProjects',
            path: '/lapinoz_viranichowk',
        },

        {
            id: '20',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/JollyEnjoy.webp',
            details: 'Jolly En’Joy Resort',
            tag: 'HospitalityProjects',
            path: '/jollyenjoy',
        },
        {
            id: '21',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/LapinozRaiyachowk.webp',
            details: 'La Pinoz, Raiya Chowk outlet',
            tag: 'HospitalityProjects',
            path: '/lapinoz_raiyachowk',
        },

        {
            id: '22',
            title: 'Hospitality Projects',
            img: '/images/portfolio/packery/Hospitality-Projects/oyeanna.webp',
            details: 'Oye Anna',
            tag: 'HospitalityProjects',
            path: '/oyeanna',
        },

        // ----------------Commercial-----------------
        {
            id: '23',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/Centroid.webp',
            details:'Centroid',
            tag: 'Commercial',
            path: '/centroid',
        },

        {
            id: '24',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/Headquaters.webp',
            details:'Headquaeters',
            tag: 'Commercial',
            path: '/headquaters',
        },
        {
            id: '23',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/InvestmentOptions.webp',
            details:'Investment Options',
            tag: 'Commercial',
            path: '/investmentoptions',
        },
        {
            id: '24',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/KpStudio.webp',
            details:'KP Studio',
            tag: 'Commercial',
            path: '/kpstudio',
        },
        {
            id: '25',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/ModernizedGurukul.webp',
            details:'Modernized Gurukul',
            tag: 'Commercial',
            path: '/gurukul',
        },
        {
            id: '26',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/ShreejiSales.webp',
            details:'Shreeji Sales',
            tag: 'Commercial',
            path: '/shreejisales',
        },

        {
            id: '26',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/StarHospital.webp',
            details:'Star Hospital',
            tag: 'Commercial',
            path: '/starhospital',
        },
        {
            id: '27',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/TheSpire.webp',
            details:'The Spire',
            tag: 'Commercial',
            path: '/thespire',
        },
        {
            id: '28',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/Ex_AngelPumpStall.webp',
            details: 'Angel pump stall',
            tag: 'Commercial',
            path: '/angelstall',
        },
        {
            id: '29',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/Ex_RbaStall.webp',
            details: 'RBA Stall',
            tag: 'Commercial',
            path: '/rbastall',
        },

        {
            id: '30',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/Ex_Sidhhigroupstall.webp',
            details: 'Siddhi Group Stall',
            tag: 'Commercial',
            path: '/siddhigroupstall',
        },
        {
            id: '31',
            title: 'Commercial',
            img: '/images/portfolio/packery/Commercial/Ex_SilverconStall.webp',
            details: 'Silvercon Stall',
            tag: 'Commercial',
            path: '/silverconstall',
        },
        // --------------3D Work----------------
        {
            id: '32',
            title: '3D Work',
            img: '/images/portfolio/packery/3dwork/Utkarsh.webp',
            details: 'उत्कृष्ट',
            tag: 'threeDwork',
            path: '/utkarsh',
        },
        {
            id: '33',
            title: '3D Work',
            img: '/images/portfolio/packery/3dwork/WesthillF1102.webp',
            details: ' Westhill F 1102',
            tag: 'threeDwork',
            path: '/westhill',
        },
        {
            id: '34',
            title: '3D Work',
            img: '/images/portfolio/packery/3dwork/ToplandVIlla.webp',
            details: 'Topland Villa',
            tag: 'threeDwork',
            path: '/toplandvilla',
        },
        {
            id: '35',
            title: '3D Work',
            img: '/images/portfolio/packery/3dwork/jolly.webp',
            details: 'Jolly En’Joy Resort, Chotila',
            tag: 'threeDwork',
            path: '/jollyenjoy3d',
        },

        {
            id: '36',
            title: '3D Work',
            img: '/images/portfolio/packery/3dwork/OrganicThemedResidence.webp',
            details: 'Organic themed residence',
            tag: 'threeDwork',
            path: '/organicresidence',
        },

        {
            id: '37',
            title: '3D Work',
            img: '/images/portfolio/packery/3dwork/ResidentialDuplex2.jpg',
            details: 'Residential Duplex',
            tag: 'threeDwork',
            path: '/resedentialduplex',
        },







    ];

    const [filterKey, setFilterKey] = useState('ResidentialBunglow')

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy()
    }, []);

    // handling filter key change
    useEffect(() => {
        // filterKey === '*'
        //     ? Isotope.current.arrange({ filter: `*` })
        //     :
        Isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey]);

    const handleFilterKeyChange = key => () => setFilterKey(key)


    return (
        <section className="projects packery">
            <SectionGridLines />
            <div className="container">
                <div className="large_font">
                    <h2 className="floating_element text-dark-200 d-flex justify-content-center">Projects</h2>
                </div>
                <div className="portfolio-filters-content">
                    <div className="filters-button-group">
                        {/* <button className="button" onClick={handleFilterKeyChange('*')}>All <sup className="filter-count"></sup></button> */}
                        <button className="button" onClick={handleFilterKeyChange('ResidentialBunglow')}>Residential Bunglow <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('ResidentialApartment')}>Residential Apartment <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('HospitalityProjects')}>Hospitality Projects <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('Commercial')}>Commercial<sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('threeDwork')}>3D Work<sup className="filter-count"></sup></button>
                    </div>
                </div>
                <div className="grid gutter-20 clearfix">
                    <div className="grid-sizer"></div>
                    {
                        ProjectData.map(data => <ProjectCard
                            key={data.id}
                            data={data}
                        />)
                    }

                </div>

            </div>
        </section>
    );
};

export default Portfolio;