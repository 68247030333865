import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Ex_Silverconstall = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Silvercon Stall" activePage="Silvercon Stall" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_SilverconStall/01.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                            <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_SilverconStall/02.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_SilverconStall/03.webp' />
                            </SwiperSlide>
                            {/* <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_SilverconStall/04.webp' />
                            </SwiperSlide> // photo strached*/}
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_SilverconStall/05.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_SilverconStall/06.webp' />
                            </SwiperSlide>
                           
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Silvercon ACC blocks LLP
</span>
                                    </li>
                                    <li>
                                        <span className="data"> Stall Size:</span> <span className="value">6 mt X 4 mt
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Property Expo and Showcase 2023, Rajkot  </span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value"> Studio Dream  </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Stall Design </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    Last but not least, we designed the stand for Silvercon Group, a manufacturer of ACC blocks. The stall received a winning award for "Design Excellence of stall - Category 3, Sustainability." As soon as we received the inquiry, we were quite sure to use their product as the design element itself. Further, we curated spaces like the seating area, discussion area, and reception with Acc blocks itself. We built the facade by laying the blocks in the header bond, with some protruding and acting as planters. Whereas in some random block, with the help of core cutting, we installed lights. For the interior, we then laid the blocks in English bond and added a block pattern at random areas to give it a dynamic look and also used cnc for their logo and pattern placed randomly. Similarly, we built a bench and reception table from the ACC block. We kept the ceiling very simple and added fabric to give it contrast. In a way, we made the product into a stall.

                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
           
</div>
  )
}

export default Ex_Silverconstall 
