import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
import MyLoader from '../../Shared/MyLoader';
// import RelatedProjects from './RelatedProjects';

const OrganicResidence = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setLoading(true);
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />;
    }
    return (
        <div>
            {/* {loading ? (
                <Loader />
            ) : ( */}
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Organic themed residence" activePage="Organic themed residence" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/OrganicThemedResidence/01.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/OrganicThemedResidence/02.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/OrganicThemedResidence/03.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/OrganicThemedResidence/04.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/OrganicThemedResidence/05.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/OrganicThemedResidence/06.webp' />
                                    </SwiperSlide>





                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value">Sirishbhai</span>
                                            </li>
                                            <li>
                                                <span className="data"> Area:</span> <span className="value">300 Sqyd
                                                </span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">  Morbi, Gujarat
                                                </span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title"> Organic-themed Residence:  Embracing New Geometry and Elements</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>

                                                In response to our client's vision for an organic-themed residence, our design team eagerly embraced the challenge. By exploring innovative geometry and incorporating various materials and elements, we successfully achieved a unique aesthetic that seamlessly blends nature and architecture. In this write-up, we will delve into the design process and highlight the key features that lend an organic touch to the facade.
                                                <br></br><br></br>
                                                To bring the organic theme to life, we ventured beyond conventional shapes and forms. Our design incorporated gentle curves and flowing lines, reminiscent of natural contours found in the environment. This departure from traditional angles and straight lines allowed us to create a harmonious connection between the built structure and the surrounding landscape. To further enhance the organic feel, we integrated green screens and strategic cut-outs into the facade design. Green screens consisting of climbing plants and vines were strategically placed to provide shade, privacy, and a lush visual backdrop. The carefully positioned cut-outs allowed for controlled natural light penetration, casting captivating patterns and creating an interplay between light and shadow throughout the day. The incorporation of glass elements played a crucial role in achieving the desired organic ambiance. The transparency of the glass elements allowed for a visual connection with nature, inviting natural light to flood the interior and fostering a sense of tranquillity and openness.

                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* )} */}
        </div>
    )
}

export default OrganicResidence 
