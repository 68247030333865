import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
import MyLoader from '../../Shared/MyLoader';
// import RelatedProjects from './RelatedProjects';

const ToplandVilla = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setLoading(true);
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />;
    }

    return (
        <div>
            {/* {loading ? (
                <Loader />
            ) : ( */}
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Topland Villa" activePage="Topland Villa" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/01.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/02.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/03.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/04.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/05.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/06.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/07.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/08.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/09.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/10.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/11.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/12.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/13.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/14.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/ToplandVilla/15.webp' />
                                    </SwiperSlide>







                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>

                                            <li>
                                                <span className="data"> Area:</span> <span className="value">9750 Sq Yd

                                                </span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">  Kankot, Rajkot, Gujarat, India</span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title"> Topland Villa</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                Every designer/architect always has some projects that are close and important to him/her on a personal level. This is one such project for us and our design team. Luxury has now become a necessity. People want to live in homes full that give them a good quality of life. Thus it’s very important to choose the right project for your dream house. This project is a combination of various luxurious villas that have been planned in a way that uses the space optimally. The central vista of the project is particularly fascinating and eye catching. It has a club house, a very interesting swimming pool in the front side of the club house with resting chairs on its pereferry. The swimming pool is designed by intersecting circles. The villas follow a strict straight pattern with straight lines seen everywhere, thus this circular pool provides a good contrast to the entire space. The space is filled with lush green plants to create a calm and serene atmosphere. There’s an area provided for children to play as well which is emclosed by a boundary so it’s easy to keep hold of them. The elevation of the villas is visually balanced by the use of materials and planes. The villas follow modern style of architecture.

                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* )} */}
        </div>
    )
}

export default ToplandVilla 
