import React from 'react';

const Publication = (props) => {
    return (
        <>
        {/* <SectionGridLines /> */}
            <div className="col-lg-4 col-md-6">
                <div className="blog_post mb-0">
                    <img src={props.img} alt="img"  />
                    <div className="blog_content">
                        <h4 className="post-count">{props.date}</h4>
                        <h5 className="">{props.mainTitle}</h5>
                        <div className="meta">
                            <time className="text-olive" dateTime={props.dateTime}>{props.subTitle}</time>
                        </div>
                        <a href={props.link} className='modernWay' target='_blank'>{props.link}<span className='link-sign'></span></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Publication
