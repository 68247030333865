import React from 'react';
import PageBanner from '../CommonPages/PageBanner';
import ServiceContainerDetails from './ServiceContainerDetails';

const ServiceDetails = () => {
    return (
        <>
            <PageBanner pageTitle='Services' subpath='/service' title="Service Details" activePage="California Mansion Residence" />
            <ServiceContainerDetails />
        </>
    );
};

export default ServiceDetails;