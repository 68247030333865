import React from 'react';
import Form from './Form';
import FormText from './FormText';
import SectionGridLines from './SectionGridLines';
import DesignPoint from './DesignPoint';

const FormContainer = () => {
    return (
        <>
            <section className="contact_us">
                <div className="container">
                    <div className="row justify-content-between position-relative">
                        <FormText></FormText>
                        <div className="col-lg-6 col-md-7">
                            <Form></Form>
                        </div>
                    </div>

                    {/* ------ Client Logo -------- */}


                </div>
                <SectionGridLines></SectionGridLines>
                <DesignPoint />
            </section>
        </>
    );
};

export default FormContainer;