import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const DiamondSquare = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Diamond Square" activePage="Diamond Square" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond1.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond6.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond7.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond8.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond9.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/DiamondSquare/diamond10.webp' />
                            </SwiperSlide>

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">  Ashwin Lodhiya</span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">1900 sqft</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Diamond Square, Rajkot</span>
                                    </li>
                                    
                                   

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Diamond Square </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    A house becomes a home not only by the people living in it, but as well the spaces created in the house and how cosy and comfortable they make you feel as soon as you enter. The elements in a house make it home. Elements like light, partition walls, lights, interactive areas etc. This is a residential project completed by our team. The living area of the apartment is done using Italian Marble, which makes the space look more inviting and gives a rich and elegant aesthetic and visual appeal. The marble sits well with the furnishing elements and the ambient ceiling lights. The ceiling lights have a soft yellow glow that emphasises the flooring and the walls. The walls have a tint if wood at door frames and various other places which stand out amongst white walls. There is a hint of strong color in between the neutral walls which make the space more interesting and lively. Wooden flooring is used in bedroom, thus differentiating the private bedroom space from the semi-private/public spaces like living room and dining area. This wooden flooring sits perfectly to create a more cosy space. There is a garden space created in the balcony using turf, a swing placed in the area makes the space a perfect spot to hang out with friends/family. Thus this way with our design aesthetic and sensibility we made our client’s house a home.
                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
            
</div>
  )
}

export default DiamondSquare
