import React, { useEffect, useState } from 'react';
import ServiceContainer from './ServiceContainer';
// import PageBanner from '../CommonPages/PageBanner';
// import Loader from '../Shared/Loader';
import { Link } from 'react-router-dom';
import MyLoader from '../Shared/MyLoader';

const Services = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>;
    }
    return (
        <>
            {/* {loading ? (
                <Loader />
            ) : (
                <> */}
                    {/* <PageBanner title="Our Services" activePage="Our Services"></PageBanner> */}
                    <div className="page_header">
                        <div className="page_header_inner service_breadcrumb" >
                            <div className="container">
                                <div className="page_header_content d-flex align-items-center justify-content-between">
                                    <h2 className="heading">Our Services</h2>
                                    <ul className="breadcrumb d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>

                                        <li className="active">Our Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: '3vmax', paddingBottom: '10vmax' }}>
                        <ServiceContainer />
                    </div>
                {/* </>
            )} */}
        </>
    );
};

export default Services;