import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import MyLoader from '../../Shared/MyLoader';
import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const LapinozVrundavan = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
         
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="La Pinoz, Vrundavan outlet" activePage="La Pinoz, Vrundavan outlet" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda1.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda2.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda3.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda4.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda5.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda6.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda7.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozVrundavan/lavrunda8.webp' />
                            </SwiperSlide>
                            
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Prafulbhai Kathrotia & Nakul Kathrotia </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value"> 861 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">   Manthan Mehta   </span>
                                    </li>
                                    <li>
                                        <span className="data">text:</span> <span className="value">   Neenu Paulose  </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title"> La Pinoz, Vrundavan outlet, Rajkot  </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    The design for this particular restaurant in the franchise was different. The design was meant to be of a soberer tone as opposed to the jazziness of the previous designs.<br></br><br></br>

                                    Elements such as fabric ceiling installations and wave-like wall projections were added which complemented each other in the form of curves and enhances the overall experience and chic look. Subway tiles were also used as a contrasting material which ties up the whole concept of subtleness. The projections also have cove lighting features which gives the walls a very clean look.<br></br><br></br>

                                    The furniture is a combination of purchased and designed pieces and adds colours to the space, but, at the same time it does not overpower the overall composition of the curvature of the elements.



                                   </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
           
</div>
  )
}

export default LapinozVrundavan
