import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import MyLoader from '../../Shared/MyLoader';
import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const LapinozViranichowk = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
         
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="La Pinoz, Virani chowk outlet" activePage="La Pinoz, Virani chowk outlet" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozViranichowk/00.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozViranichowk/01.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozViranichowk/02.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozViranichowk/05.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozViranichowk/06.webp' />
                            </SwiperSlide>
                            
                            
                            
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value"> Prafulbhai Kathrotia & Nakul Kathrotia  </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value"> 988 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">   Murtaza Gandhi     </span>
                                    </li>
                                    <li>
                                        <span className="data">Text:</span> <span className="value">   Neenu Paulose   </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title"> La Pinoz, Virani chowk outlet </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    A quaint little pizzeria located at a neighbourhood buzzling with activity.<br></br><br></br>

                                    The client’s main requirement was to create a design that would enhance user experience in the best way possible. This was achieved in a very effective way.<br></br><br></br>

                                    The concept behind the design of this space was to give it a rustic feel. The usage of greenery and the interior landscape was also an important part of the concept. These criteria were met by the use of exposed brick in the arches. These arches, concrete-finished walls, and simple wooden furniture add to the rustic elements. Green elements can be seen in the partitions. The design choices were made by keeping the user and their experience in mind.<br></br><br></br>

                                    The project was completed in a matter of 2 months well within the client’s budget. The subtle design emanates a very comfortable ambience and has become a hub for pizza lovers within a very short period of time.


                                   </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
          
</div>
  )
}

export default LapinozViranichowk
