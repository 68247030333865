import React, { useEffect, useState } from "react";
import { EffectFade, Navigation, Autoplay } from "swiper";
import PageBanner from "../../CommonPages/PageBanner";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionGridLines from "../../CommonPages/SectionGridLines";
import { Outlet } from "react-router-dom";
import ProjectDetailsSlider from "../ProjectDetailsSlider";
// import Loader from "../../Shared/Loader";
import MyLoader from "../../Shared/MyLoader";


const ResedentialDuplex = () => {
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>;
    }
  return (
    <div>
      {/* {loading ? (
        <Loader />
      ) : ( */}
        <main className="wrapper">
          <SectionGridLines />
          <Outlet />
          <PageBanner
            pageTitle="Projects"
            subpath='/projects'
            title="Resedential Duplex"
            activePage="Resedential Duplex"
          />
          <section className="project-details bg-dark-200">
            <div className="container">
              <div className="gallery_slider">
                <div className="swiper swiper_gallery">
                  <Swiper
                    modules={[EffectFade, Navigation, Autoplay]}
                    effect="slide"
                    slidesPerView={1}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={{
                      prevEl: ".swiper-button-prev",
                      nextEl: ".swiper-button-next",
                    }}
                  >
                    <SwiperSlide>
                      <ProjectDetailsSlider
                        img="images/portfolio/details/3D-Work/ResidentialDuplex/01.webp"
                        style={{ height: "40rem" }}
                      />
                    </SwiperSlide>
                    {/* photo strached */}
                    <SwiperSlide>
                      <ProjectDetailsSlider img="images/portfolio/details/3D-Work/ResidentialDuplex/02.webp" />
                    </SwiperSlide>
                    {/* photo strached */}

                    <SwiperSlide>
                      <ProjectDetailsSlider img="images/portfolio/details/3D-Work/ResidentialDuplex/03.webp" />
                    </SwiperSlide>
                    {/* photo strached */}
                  </Swiper>

                  {/* <!-- Add Buttons --> */}
                  <div className="swiper-navigation">
                    <div className="swiper-button-prev">
                      <i className="bi bi-arrow-left"></i>
                    </div>
                    <div className="swiper-button-next">
                      <i className="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="sidebar">
                    <div className="project-information">
                      <h6 className="widget-title">
                        Project Information
                        <span className="title-line"></span>
                      </h6>
                      <ul>
                        <li>
                          <span className="data">Client:</span>{" "}
                          <span className="value">Khunt Family</span>
                        </li>
                        <li>
                          <span className="data"> Area:</span>{" "}
                          <span className="value">5580 Sqft</span>
                        </li>
                        <li>
                          <span className="data">Location:</span>{" "}
                          <span className="value"> Rajkot, Gujarat </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-8">
                  <div className="project-details_inner">
                    <div className="post_content">
                      <div className="post-header">
                        <h3 className="post-title"> Residential Duplex</h3>
                      </div>
                      <div className="fulltext">
                        <p>
                          The client’s requirements was 4-BHK bungalow, with
                          optimal visual connectivity and openness of plan.
                          Clients were looking for something that was a blend of
                          contemporary. For the purpose, the elevation of 1st &
                          3rd floor are similar, as well as 2nd & 4th floor are
                          also same as per design. Fine brick masonry work has
                          accentuated the desired finish and aesthetics of the
                          project. Clean details have lead to easy transformation
                          of design to reality. The identity of an expose plaster
                          wall that entire height of both floors to visually
                          connect.<br></br>
                          <br></br>
                          When the residence was completed, the family was
                          delighted with the home’s natural light, openness and
                          freshness the ample living and personal spaces and the
                          lovely balcony that had been opened up. They now look
                          forward to having their morning tea on the balcony, with
                          a view of the treetops, and soaking in the comfort of
                          their new home. We believe in designing spaces that suit
                          the client’s personality and requirements. During
                          interactions with clients we take cues from them. These
                          become our point of departure for designing as we
                          interpret them and take the process forward.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      {/* )} */}
    </div>
  );
};

export default ResedentialDuplex;
