import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Kpstudio = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="KP Studio" activePage="KP Studio" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/KpStudio/01.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/KpStudio/05.webp' />
                            </SwiperSlide>
{/*                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Kpstudio/03.webp' />
                            </SwiperSlide>
                           
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Kpstudio/04.webp' />
                            </SwiperSlide> */}
                           
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/KpStudio/06.webp' />
                            </SwiperSlide>
                           
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/KpStudio/07.webp' />
                            </SwiperSlide>
                           
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/KpStudio/08.webp' />
                            </SwiperSlide>
                           
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Ketshi Pujara </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">525 sqft   
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value">  Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Text:</span> <span className="value">  Neenu Paulose </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">KP Studio</h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    The design idea was to revamp the interiors of a residence and convert it into a fashion studio. The idea was to make the space have the most circulation around the display, while the interior elements were designed in such a way that would reflect the store designer's ideology.<br></br><br></br>

This was achieved by using subtle tones in the forms of wallpaper, metal, fabric, laminate, and wood. The wallpaper adds a subtle beige undertone and a rich look with gold motif patterns thereby giving the merchandise the center stage. Accent walls have been provided for the mannequin displays which frame them in a beautiful manner.<br></br><br></br>

The ground floor of the house is the display area for sales while the first floor acts as the workshop for the designers. The most challenging part for us during this project was to design and execute in just 20 days, which we were able to achieve

                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
           
</div>
  )
}

export default Kpstudio 
