import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
import MyLoader from '../../Shared/MyLoader';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Ex_AngelPumpStall = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
      
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Angel Pump Stall" activePage="Angel Pump Stall" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_AngelPumpStall/01.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_AngelPumpStall/02.webp' />
                            </SwiperSlide>
                           
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_AngelPumpStall/06.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_AngelPumpStall/07.webp' />
                            </SwiperSlide>
                           
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Angel Pump
</span>
                                    </li>
                                    <li>
                                        <span className="data"> Stall Size:</span> <span className="value">12 mt X 10 mt
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Property Expo and Showcase 2023, Rajkot  </span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value"> Studio Dream  </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Stall Design </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    The concept for Stall II was developed with an emphasis on stall circulation. We designed the circulation in a circular shape so that customers could examine the merchandise from all directions, with the lounge seating, serving as the main point and providing a 360-degree view of all the products. Further clients wanted a separate discussion area as well. Which we offered at the back end, creating a closed-off private area. In addition, the stall contained a pantry and a waiting room. We added plants as elements in the space made the stall look more lively. To give the stall an ambience hanging lights were installed in circular form.

                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
           
</div>
  )
}

export default Ex_AngelPumpStall 
