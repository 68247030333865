import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const SidhdhiHeritage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
    return (
        <div>
            
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Sidhdhi Heritage 2BHK" activePage="Sidhdhi Heritage 2BHK" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/01.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/02.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/03.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/04.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/05.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/06.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/07.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage2BHK/08.webp' />
                                    </SwiperSlide>

                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value"> Siddhi Group</span>
                                            </li>
                                            <li>
                                                <span className="data">Carpet Area:</span> <span className="value">636 Sq Ft</span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">Rajkot, Gujarat</span>
                                            </li>
                                            <li>
                                                <span className="data">Photography:</span> <span className="value">Murtaza Gandhi</span>
                                            </li>
                                            <li>
                                                <span className="data">Text:</span> <span className="value">Neenu Paulose</span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title">Sidhdhi Heritage 2BHK</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                Affordable elegance and beauty.
                                                The client wanted us to design a sample or show flat for their apartments. The idea was to make the flat look spacious as the space was limited in size. This was achieved by using light shade laminates and paints throughout. A lot of the colour shades used are calm and soothing.
                                                The highlighter in the space is the wall behind the hand wash area. Rather than giving it a muted feel, we decided to draw attention to the space by making it an accent wall with an exposed brick pattern. The mirror has been treated using a similar tessellation giving it a very similar effect in such a way that the language of the pattern is uninterrupted.
                                                With a combination of interesting colours and lighting elements, the house has been made to look spacious and elegant. The furniture used goes with the light theme and gives the space a posh yet very simple look.
                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
           
        </div>
    )
}

export default SidhdhiHeritage
