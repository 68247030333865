import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Sakariya = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>
           
            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Sakariya Villa" activePage="Sakariya Villa" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/01.webp' style={{ height: '40rem' }} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/02.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/03.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/04.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/05.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/06.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/07.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/08.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/09.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/SakariyaVilla/10.webp' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">

                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value">Sakariya Family</span>
                                                </li>
                                                <li>
                                                    <span className="data">Area :</span> <span className="value">1000 Sq yard</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Jetpur, Gujarat</span>
                                                </li>
                                                

                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">

                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Sakariya Villa</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                Residential villas in today’s modern progressive world demand modern facilities as well. This villa was one such project that demanded luxury at its best and resort living kind of amenities and comfort where you go for relaxing and leisure. So we thought what if the resort can be brought to your home and your home becomes and epitome of it, one wouldn’t step out of it. This villa has been crafted using our design sensibilities and aesthetic in a way that the different materials used throughout coexist in harmony and complement each other to create a visually and physically appealing space. The living areas have been crafted using Italian marble on the floors. There is usage of various lights across the villa to create ambient atmosphere for the family. The false ceiling done have an artistic touch to it, thus when you look up it looks more like a canvas that sets it apart from usual mundane ceilings. There is a home theatre room in which recliners are kept for the comfort of the viewer. The house Is a perfect execution of the wishes put forth by our clients as to how they wanted their home to be like.touch.
                                                </p>
                                              



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
           
        </div>
    )
}

export default Sakariya
