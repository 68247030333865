import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';

const Jasani = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
    return (
        <div>
            
            <div>   
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Serenity Apartment" activePage="Serenity Apartment" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani1.webp' style={{ height: '40rem' }} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani2.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani3.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani4.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani5.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani6.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani7.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani8.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani9.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani10.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Jasani/jasani11.webp' />
                                        </SwiperSlide>


                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">

                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value"> Dr Anand Jasani</span>
                                                </li>
                                                <li>
                                                    <span className="data">Carpet Area:</span> <span className="value"> 3000 sqft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Silver Heights, Rajkot</span>
                                                </li>
                                                {/* <li>
                                                    <span className="data">Photography:</span> <span className="value">Manthan Mehta </span>
                                                </li> */}


                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">

                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Dr. Jasani’s Home </h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                Fusion of sensibility and sensitivity that ensures their existence through the design. Accommodation of each space enhances the Combination of peace, comfort, style, and perfection. The living room has perspective to the pooja room and the garden space which is designed and craved up in a way that they do not create visual barriers. The TV unit wall is embellished by craved paneling with back-light and wallpaper, aesthetically flickering the entire space by hanging lights on ceiling. With elegant profile lights on ceiling and polished wooden belts adding plush look to whole of the area. Connecting the garden area, lightning of the space provides the natural as well as intermediate lights on ceiling creates an ambient effect. Walking to the royalty and simplicity of the rooms, the use of Italian marble, mirror , painting , veneers and warm lights illuminates the space. The subsistence of kitchen encourages the appetite by incorporating yellow highlights tones along with the tiles enlivens it. The project is planned well with a pinch of improved quality of life.
                                                </p>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
           
        </div>
    )
}

export default Jasani
