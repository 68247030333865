import React, { useEffect, useState } from 'react';
import AboutContainer from './AboutContainer';
import FormContainer from '../CommonPages/FormContainer';
import FunFacts from '../CommonPages/FunFacts';
import Sliders from './Sliders';
import Testimonials from '../CommonPages/Testimonials';
import { Outlet } from 'react-router-dom';
import Portfolio from '../ProjectPages/Portfolio';
import ServiceContainer from '../ServicePages/ServiceContainer';
import MyLoader from '../Shared/MyLoader';
// import RunningProjects from './RunningProjects';
// import SectionGridLines from '../CommonPages/SectionGridLines';
// import ServiceContainer from '../ServicePages/ServiceContainer';
// import Services from './Services';
// import Loader from '../Shared/Loader';

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>;
    }
    return (
        <>
            {/* {loading ? (
                <Loader />
            ) : (
                <> */}
                    <div className="wrapper">
                        {/* <SectionGridLines /> */}
                        <Outlet />
                        <Sliders />
                        <ServiceContainer/>
                        <AboutContainer />
                        <FunFacts className='funfacts bg_1' />
                        {/* <RunningProjects /> */}
                        <div>
                           
                            <Portfolio />
                        </div>
                        <Testimonials />
                        <FormContainer />
                    </div>
                {/* </>
            )} */}
        </>

    );
};

export default Home;