import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
import MyLoader from '../../Shared/MyLoader';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Westhill = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setLoading(true);
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />;
    }
    return (
        <div>
            {/* {loading ? (
                <Loader />
            ) : ( */}
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Westhill F 1102" activePage="Westhill F 1102" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/01.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/02.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/03.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/04.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/05.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/06.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/07.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/08.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/09.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/10.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/11.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/WesthillF1102/12.webp' />
                                    </SwiperSlide>






                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value">Ashwin Bhai</span>
                                            </li>
                                            <li>
                                                <span className="data"> Area:</span> <span className="value">4830 Sqft

                                                </span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">  Rajkot, Gujarat</span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title"> Westhill F 1102</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                Crafted with precision and draped in elegance, this 3D masterpiece embodies the essence of luxury. Every line, and every hue tells a story of sophistication.
                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* )} */}
        </div>
    )
}

export default Westhill 
