import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const StarHospital = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Star Hospital" activePage="Star Hospital" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/00.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/01.webp' />
                            </SwiperSlide>
                            {/* <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/02.webp' />     // photo strached
                            </SwiperSlide> */}
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/03.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/04.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/05.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/06.webp' />
                            </SwiperSlide>
                            {/* <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/StarHospital/07.webp' />   // photo strached
                            </SwiperSlide> */}
                           
                           
                          
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Dr. Nilay Pabani and Dr. Bhumi Pabani</span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">840 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value">  Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">   Murtaza Gandhi  </span>
                                    </li>
                                    <li>
                                        <span className="data">Text:</span> <span className="value">    Neenu Paulose </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Star Hospital, Rajkot</h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    A clinic with an aim to provide dental excellence and diabetic care.
<br></br><br></br>

This clinic was designed to accommodate various spaces as required within a limited area. The couple wanted individual cabins for themselves as the husband's field is diabetics while the wife's field is dentistry. This facilitated the meetings with the patients without any hindrance. The comfort of the patients was of utmost importance in the design.
The idea was to accommodate all necessary requirements without clutter. This led to the idea of adding a mezzanine space and including a staircase as an aesthetic element. The mezzanine houses the dental clinic while the diabetics section is on the ground floor. The design of the staircase is an interesting play within the volume. Green spaces have also been added to bring about a sense of freshness.
<br></br><br></br>
Being a dental clinic, the main concept was to make sure that the patients would be at ease and feel calm. Subtle tones of white and blue were used as the colour palette which definitely brings about a sense of serenity in space.


                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
          
</div>
  )
}

export default StarHospital 
