import React from 'react';
import ContactForm from './ContactForm';

const ContactContainer = () => {

    return (
        <main className="wrapper">
            <section className="gmap box_padding">

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.6538354099175!2d70.78734007503273!3d22.29109904322937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cb65ba895cbf%3A0xdf174ef58c1a118c!2sShreesh%20Design%20Studio%20LLP!5e0!3m2!1sen!2sin!4v1702448597872!5m2!1sen!2sin" height={200}></iframe>

            </section>

            <ContactForm />
        </main>
    );
};

export default ContactContainer;