import React from 'react';

const FormText = () => {
    return (
        <div className="col-lg-5 col-md-5 px-2">
            <div className="section-header">
                <h3 className="text-white text-uppercase mb-4">Let’s talk about next projects</h3>
                <p className='mb-3 text-gray-300'>Your dreams, our efforts! </p>
                <p>Passion is not taken for granted at Shreesh Design Studio LLP. It is the one motto that binds the immense talent that makes the team of this studio. Reach out to us and together, let’s make a haven that fuels our lives.</p>
            </div>
        </div>
    );
};

export default FormText;