import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Gor = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
       
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Dr. Gor Appartment " activePage="Dr. Gor Appartment " />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor1.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor6.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor7.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor8.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor10.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor9.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Gor/gor11.webp' />
                            </SwiperSlide>
                           

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">  Dr. Bhavin Gor</span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">3200 Sqft</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Text:</span> <span className="value">Jhanvi Patel</span>
                                    </li>
                                   

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Dr. Gor Appartment </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    
This particular residential project of a 3200 sqft area was designed for an avid nature and a memory embracer. On the one hand, it is about subtlety, and on the other, it is about opulence. Wood is the predominant basic material used, with an extra finishing coat.

As you enter the house through its main door, it is a truly surreal experience. The visual and physical monumentality of the design lends the place a beautiful touch. The doorway leads to an entrance foyer that has a massive art piece of Ganesha which enhances the entire sanctity of the space. The leading wall from the entrance into the primary spaces has a wooden framework around the walls, each of them specifying memories and artworks. This particular requirement of a memory wall has been highlighted throughout the house, irradiating every part with a souvenir. Opening to common areas the light color of these floors used throughout the house makes the mind perceive the space to be much bigger. Moreover, each corner has been illuminated with a pop of colors and chandeliers which gathers and holds all elements together. The hall is then connected with an open study area, which is designed with a splash of natural light coming in and a walk-in balcony to relax at the same time. The kitchen, beautifully crafted in a similar color tone and bold throw of a darker shade to enhance it. When looked at the bedrooms, they are all shaped with the same color palette, wooden highlight, and artworks placed with a spotlight, cumulating the concept followed throughout the house.

                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
            
</div>
  )
}

export default Gor
