import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';

const GardenCity = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
    return (
        <div>
            
            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title=" Garden City" activePage=" Garden City" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/1.webp' style={{ height: '40rem' }} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/2.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/3.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/4.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/5.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>  
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/6.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/7.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Garden/8.webp' />
                                        </SwiperSlide>


                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">

                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value"> Jayeshbhai Amrutiya</span>
                                                </li>
                                                <li>
                                                    <span className="data">Area:</span> <span className="value">2200 Sqft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Rajkot, Gujarat</span>
                                                </li>
                                                <li>
                                                    <span className="data">Text By:</span> <span className="value">Jhanvi Patel </span>
                                                </li>


                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">

                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title"> Garden City</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    The main aim of this project was the art of creating aesthetic and functional living spaces with the use of moderate material and color palette. A thought of ascetic use of white has an airy appearance throughout the space making it clean and exclusive to the viewer. The living room has a subtle whitewashed brick wall which gives character to the entire space. Moreover, the flooring is white which creates a very crisp and pure look contrasting with the warmer shades. The balanced factor adapted to design each room with contrasting material, provides a uniform tranquility to the space. In addition, the indirect proportion of warm lights promotes relaxation, thus enlightens the entire area with positivity.
                                                </p>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
           
        </div>
    )
}

export default GardenCity
