import React from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import Paragraph from '../CommonPages/components/Paragraph';
import PointOrder from '../CommonPages/components/PointOrder';
import PointOrder02 from '../CommonPages/components/PointOrder02';
import WidgetTitle from '../CommonPages/components/WidgetTitle';
import PostHeader from '../CommonPages/components/PostHeader';

const ServiceContainerDetails = () => {
    const GalleryImg = [
        {
            id: '1',
            img: '/images/portfolio/packery/7.webp',
        },
        {
            id: '2',
            img: '/images/portfolio/packery/8.webp',
        },
        {
            id: '3',
            img: '/images/portfolio/packery/9.webp',
        },
        {
            id: '4',
            img: '/images/portfolio/packery/10.webp',
        }
    ]

    return (
        <main className="wrapper">
            <section className="project-details bg-dark-200">
                <div className="container">
                    <div className="gallery_slider">
                        <div className="swiper swiper_gallery">
                            <Swiper
                                modules={[EffectFade, Navigation,Autoplay]}
                                effect="slide"
                                slidesPerView={1}
                                loop={true}
                                autoplay={{
                                    delay: 6000,
                                }}
                                navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }}
                            >
                                {
                                    GalleryImg.map(data =>

                                        <SwiperSlide>
                                            <div className="gallery-image">
                                                <img src={data.img} alt="img" />
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                                
                            </Swiper>

                            {/* <!-- Add Buttons --> */}
                            <div className="swiper-navigation">
                                <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="sidebar">
                                <div className="service-information">
                                    <PointOrder02 href='/service-details' li1='Landscape Design' li2='Product Design' li3='Blueprint Design' li4='Urban Design' li5='Interior Design' />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="project-details_inner">
                                <div className="post_content">
                                    <PostHeader title='California Mansion Residence' />
                                    <div className="fulltext">
                                        <p text='Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture, space planning and programming. Our portfolio of completed work includes highly acclaimed and award-winning projects for clients around the country.' />

                                        <WidgetTitle title='Project Features' />
                                        <Paragraph text='We design with people in mind and use every expertise at our disposal.Our practice connects communities and is committed to the stewardship of place, people, and the environment.' />

                                        <PointOrder li1='The talent at Shreesh runs wide and deep. Across many markets, geographies & typologies.' li2='Our team members are some of the finest professionals in the industry.' li3='Organized to deliver the most specialized service possible and enriched by the depth.' />

                                        <Paragraph text='Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture, space planning and programming. Our portfolio of completed work includes highly acclaimed and award-winning projects for clients around the country.' />

                                        <Paragraph text='You don’t create unforgettable spaces all over the world with a single design tool. Our expertise in drawing people together is as broad as it is deep. It draws from a variety of disciplines, each one contributing to the bigger picture and sustainable growth. More than 3,000 projects fill our portfolio, but it’s the millions of people who experience them who matter most. We’ve grouped our work into five categories: places, venues, spaces, experiences and events. Click on the images below to learn more below about the impact our projects have on communities.' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ServiceContainerDetails;