import $ from 'jquery';
import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import Header from './Headers/Header';

const MyLoader = (props) => {
    useEffect(() => {
        // Preloader
        setTimeout(function () {
            $('#preloader').addClass('hide');
        }, 2000);

        // $("#preloader").append('<div className="hide-loader">Hide Preloader</div>');
        // $('.hide-loader').click(function (e) {
        //     $(this).parent().addClass('hide');
        // });
    }, []);

    return (
        <>
            <div id="preloader">
                <Header />

                <ContentLoader
                    viewBox="0 0 400 160"
                    height={160}
                    width={400}
                    // backgroundColor="transparent"
                    {...props}
                >
                    
                    <circle cx="150" cy="86" r="8" />
                    <circle cx="194" cy="86" r="8" />
                    <circle cx="238" cy="86" r="8" />
                    {/* <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="250" height="10" /> */}
                </ContentLoader>
            </div>
        </>
    );
};
export default MyLoader

