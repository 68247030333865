import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const YamunaProject = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>
            
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Yamuna Palace" activePage="Yamuna Palace" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation,Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/1.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/2.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/3.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/4.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/5.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/6.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/7.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/8.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/9.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/10.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/11.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/12.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/13.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/14.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/yamuna/15.webp' />
                                    </SwiperSlide>


                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value">Mehul Bhai (Hanuman Dyeing)</span>
                                            </li>
                                            <li>
                                                <span className="data">Carpet Area:</span> <span className="value">4,890 Sqft</span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">Jetpur, Gujarat</span>
                                            </li>
                                            <li>
                                                <span className="data">Photography:</span> <span className="value">Nilesh Joshi </span>
                                            </li>
                                            <li>
                                                <span className="data">Text:</span> <span className="value">Nishal Sevak </span>
                                            </li>

                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title">Yammuna Palace</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                Nestled in the city of Jetpur, Gujarat, the private residence's first impression is of luxe, and grandeur. The modern inkling of the entrance in contemporary materials and treatment is seen at first glance. A division of the facade into rectangular or square shape and sizes give it a sort of linearity. There is a subtle balance of grey, white, and brick texture. The colours and texture complement each other and bring out the essence of modern living and aesthetical appeal. The primary focus of the designers was to fabricate a home rooted in the Indian mindset with a contemporary touch.
                                            </p>
                                            <p>
                                                'Yamuna Palace' justifies the design ideology as it speaks of traditionalism amalgamated with a contemporary approach. The blend of teak wood veneer and opera Fantastico Italian marble makes an impeccably charming welcome to the house. The living room is a double-height space with vivid and energetic tones. An intricate stone artwork adorns the back wall soaring to two floors of double-height. The mural carries religious importance with a mixture of white and yellow hues which is a perfect neutral background against the colourful furniture arranged in the space. Adjacent to it lies an informal seating area dripping in teal blue-coloured couches. In line with this seating is the dining area followed by the kitchen.
                                            </p>
                                            <p>
                                                'Yamuna Palace' is an ode to the diverse eccentricity that is Indian culture, art, religion, and heritage. The creative and diverse approach of infusing contemporary and traditional design concepts is a skilful art that has to be mastered with precision and the designers have conveyed it with a crisp understanding.
                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
          
        </div>
    )
}

export default YamunaProject
