import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
import MyLoader from '../../Shared/MyLoader';
// import RelatedProjects from './RelatedProjects';

const Intothewild = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
         
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Into The Wild" activePage="Into The Wild" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/00.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/1.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/Intothewild/6.webp' />
                            </SwiperSlide>
                            
                            
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value"> Tarang Patel and Jaydeep Patel </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value"> 2800 sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value">  Sadhuvasvani road, Rajkot</span>
                                    </li>
                                   
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title"> Into the Wild (Jungle theme restaurant)</h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    We have become so busy dwelling in our concrete jungles filled with high rises sky scrapers and modern houses that we lose the touch of nature somewhere in the process. Nature that soothes our eyes and gives a serene relaxation. Restaurants and cafes that have come up recently try to fill the gap between man and nature. One such project was done by our team. In this project we have tried to capture the essence of raw nature, true to its form, beautiful but wild. The idea was to make one feel like they have entered a completely different zone all together. The inside it a different idea from what the customers experience outside the restaurant. The columns have been made to look like they are a part of nature, and yet have the idea of bricks that is very urban. The ceiling has been covered using artificial plants that are look alikes of the real ones. They hide the ceiling as if it didn’t exist at all and that you’re sitting under a canopy filled with plants and flowers doing justice to the brief of ideas and wishes of our client. The flooring is kept transitional with different texture to give a raw feeling. The walls have been made interactive by recreating the characters of the most famous ‘jungle book’ beloved of most of the children. There is an artificial tree created with creepers and climbers to give a feel like its real, by giving importance to the minor details. Thus the idea of having a meal in the wild is fulfilled in an interesting and catchy way.

                                   </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
            
</div>
  )
}

export default Intothewild
