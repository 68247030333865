import $ from 'jquery';
import React, { useEffect } from 'react';
import { Link, a } from 'react-router-dom';

const MobileResponsiveMenu = () => {
    // var counter = 0;
    // function tablet() {
    //     function checkMedia(mql) {
    //         if (mql.matches) {
    //             counter++;
    //             $(".mrmenu .mr_navmenu");
    //             if (counter === 1) {
    //                 menuClickAction();
    //             }

    //         } else {
    //             $(".header .mainnav");
    //             if (counter === 1) {
    //                 menuClickAction();
    //             }

    //         }
    //     }
    //     const mql = window.matchMedia('screen and (max-width: 1199.90px)');

    //     checkMedia(mql);
    //     mql.addEventListener('change', checkMedia);
    // }
    // function menuClickAction() {
    //     // Mobile Responsive Menu 
    //     var mobileMenuContent = $('.mainnav').html();
    //     $('.mrmenu .mr_navmenu').append(mobileMenuContent);
    //     $('.mrmenu .mr_navmenu ul.mainmenu li.menu-item-has-children').append($("<span class='submenu_opener d-lg-none'><i class='bi bi-chevron-right'></i></span>"));
    // }
    useEffect(() => {

        // Sub-Menu Open On-Click
        $('.mrmenu ul.main_menu li.menu-item-has-children .submenu_opener').on("click", function (e) {
            $(this).parent().toggleClass('nav_open');
            $(this).siblings('ul').slideToggle();
            e.stopPropagation();
            e.preventDefault();
        });

        // Active Mobile Responsive Menu : Add Class in body tag
        $('.mr_menu_toggle').on('click', function (e) {
            $('body').addClass('mr_menu_active');
            e.stopPropagation();
            e.preventDefault();
        });
        $('.mr_menu_close').on('click', function (e) {
            $('body').removeClass('mr_menu_active');
            e.stopPropagation();
            e.preventDefault();
        });
        $('.mr_menu_overlay').on('click', function (e) {
            $('body').removeClass('mr_menu_active');
            e.stopPropagation();
            e.preventDefault();
        });

    }, []);

    return (
        <div className="mrmenu d-lg-none d-block">
            {/* <div className="mr_menu d-xl-none d-block"> */}
            <div className="mr_menu_overlay"></div>
            <button type="button" className="mr_menu_close"><i className="bi bi-x-lg"></i></button>
            <div className="logo">
                {/* <Link to="/"><img src="images/logo-white.png" alt="logo" /></Link> */}
                <a href='/' className="light_logo"><img src="/images/shreesh-logo.png" style={{ filter: 'invert(100%)', WebkitFilter: 'invert(100%)',height:'4rem' }} alt="logo" /></a>
                <a href='/' className="dark_logo"><img src="/images/shreesh-logo.png" alt="logo" /></a>
            </div>
            {/* <div className="mrnavmenu"></div> */}
            <div className="mr_navmenu">
                <ul className='mainmenu'>
                    <li className="menu-item"><a href="/">Home</a></li>
                    <li className="menu-item"><a href="/projects">Projects</a></li>
                    <li className="menu-item"><a href="/about">About Us</a></li>
                    <li className="menu-item"><a href="/service">Services</a></li>
                    <li className="menu-item"><a href="/achievement">Achievement</a></li>
                    <li className="menu-item"><a href="/contact">Contact Us</a></li>
                </ul>
            </div>
        </div>
    );
};

export default MobileResponsiveMenu;