import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'

const Form = () => {
    const form = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [sendMail, setSendMail] = useState('Send Message');
    const numberlimit = event => {
        const limit = 10;
        setPhone(event.target.value.slice(0, limit));
    };
    const sendEmail = (e) => {
        e.preventDefault();
        setSendMail('Sending...')
        emailjs.sendForm("service_q8344ge", "template_foomwrp", form.current, 't2_gdaW73CDBkb2fL')
            .then((result) => {
                setName("");
                setEmail("");
                setMessage("");
                setPhone("");
                setSendMail("Send message");
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Your Email has been send",
                    showConfirmButton: false,
                    timer: 1500
                });

            }, (error) => {
                setName("");
                setEmail("");
                setMessage("");
                setPhone("");
                setSendMail("Send message");
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    showConfirmButton: false,
                    timer: 1500
                });;
            });
    };
    return (
        <div className="home_contact">
            {/* <form ref={form} onSubmit={sendEmail} action="contact.php" method="POST"> */}
            <form ref={form} onSubmit={sendEmail}>
                <input className="form-control form-control-lg" name="name" id="name" value={name} onChange={(e) => { setName(e.target.value) }} type="text" placeholder="Your Name*" required  />
                <input className="form-control form-control-lg" name="phone" id="phone" value={phone} onChange={numberlimit} type="number" placeholder="Your Phone No*" required  />
                <input className="form-control form-control-lg" name="email" id="email" value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder="Your Email*" required  />
                <textarea className="form-control pt-4" name="message" id="message" value={message} onChange={(e) => { setMessage(e.target.value) }} placeholder="Your Message*" rows="3" required></textarea>
                <div className="btn_group">
                    <button type="submit" className="btn olive" value="Send">{sendMail}</button>
                </div>
            </form>
        </div>

    );
};

export default Form;