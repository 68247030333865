import React from 'react';
import { Link } from 'react-router-dom';

const PageBanner = (props) => {
    return (
        <>
            <div className="page_header">
                <div className="page_header_inner project_breadcrumb">
                    <div className="container">
                        <div className="page_header_content d-flex align-items-center justify-content-between" >
                            <h2 className="heading">{props.title}</h2>
                            <ul className="breadcrumb d-flex align-items-center">
                                <li><Link to="/">Home</Link></li>
                                {
                                    props.subpath &&
                                    <li><Link to={props.subpath}>{props.pageTitle}</Link></li>
                                }
                                <li className="active">{props.activePage}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageBanner;