import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
import MyLoader from '../../Shared/MyLoader';
// import RelatedProjects from './RelatedProjects';

const GrandHotel = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="The grand imperial hotel" activePage="The grand imperial hotel" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand1.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand6.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand7.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand8.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/TheGrandEmperialHotel/grand9.webp' />
                            </SwiperSlide>
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value"> The grand imperial hotel</span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value"> About 1500 sqyd
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Morbi</span>
                                    </li>
                                    <li>
                                        <span className="data">Text:</span> <span className="value">     Jhanvi Patel</span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">The Grand Imperial Hotel </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    The Grand Imperial hotel in Morbi has extraordinary design focus and space planning of 21 rooms. It is based on the minimal requirement of the client. In addition, simplicity and minutest use of material has glowed to give a aesthetical approach to each space. The strike of balance between colors, indicating in such manner that it maintains classiness. The intensity and hue of the lighting creates a huge impact on guest’s experience. Therefore, using warm and indirect lighting can highlight each space, creating a relaxing atmosphere. Moreover, the use of same concept and color palate enhance the connectivity and elegancy throughout.
                                   </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
          
</div>
  )
}

export default GrandHotel
