import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const SidhdhiHeritage3 = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
    return (
        <div>
             
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Sidhdhi Heritage 3BHK" activePage="Sidhdhi Heritage 3BHK" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/01.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/02.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/03.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/04.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/05.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/06.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/07.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SiddhiHeritage3BHK/08.webp' />
                                    </SwiperSlide>

                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value"> Siddhi group, Rajkot</span>
                                            </li>
                                            <li>
                                                <span className="data">Carpet Area:</span> <span className="value"> 1122 Sqft</span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">Rajkot, Gujarat</span>
                                            </li>
                                            <li>
                                                <span className="data">Text:</span> <span className="value">Devanshi Vyas</span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title">Sidhdhi Heritage 3BHK</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                The design goal for this 3 BHK sample flat was to create a perfect amalgamation of elegance, vibrancy, and coziness. Shades of blues & earthen color scheme are used in the whole house as a theme. The geometrically aligned veneer on the living area ceiling was a grueling task. We had no clue who was going to live there as it was a sample flat, but it was our responsibility to make it simple & elegant for all types of clients. The orientation has also allowed the interiors to be naturally ventilated by the free flow of wind throughout the house. The master bedroom’s highlight is the combination of blues & wood in the back paneling. The use of lighting & greens adds a totally exquisite look to the room. The remaining bedrooms had to be simple and at the same time eye-pleasing. The use of earthen blues soothes the eyes and wood being an all-time-star adds remains alluring. The living, a place to relax and make memories with friends and loved ones have beautiful paintings & 3d panels with some customized artworks in this minimal design. It has a color play in its furnishing which is a visual delight. The kitchen is designed with subtle white color as it is having a highlighted natural brick wall for the basin area. Keeping the cost of the project in check but at the same time giving a tactile response. We believe one must also feel the materials and their authenticity.
                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            
        </div>
    )
}

export default SidhdhiHeritage3
