import React, { useEffect, useState } from 'react';
import FormContainer from "../CommonPages/FormContainer";
import MissionContainer from "./MissionContainer";
import TeamContainer02 from './TeamContainer';
import Testimonials from '../CommonPages/Testimonials';
import TextAbout from './TextAbout';
import SectionGridLines from '../CommonPages/SectionGridLines';
import { Link } from 'react-router-dom';
import MyLoader from '../Shared/MyLoader';
// import Video from "../components/Video";
// import FunFacts from '../CommonPages/FunFacts';
// import ServiceContainer01 from '../containers/ServiceContainer';
// import ServiceContainer from '../ServicePages/ServiceContainer';
// import Loader from '../Shared/Loader';
// import PageBanner from '../CommonPages/PageBanner';

const AboutUs = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>;
    }

    return (
        <>
            {/* {loading ? (
                <Loader />
            ) : (
                <> */}

                    <main className="wrapper">
                        {/* <PageBanner pageTitle='Pages' title="About Us" activePage="About Us"></PageBanner> */}
                        <div className="page_header">
                            <div className="page_header_inner about_breadcrumb" >
                                <div className="container">
                                    <div className="page_header_content d-flex align-items-center justify-content-between">
                                        <h2 className="heading">About Us</h2>
                                        <ul className="breadcrumb d-flex align-items-center">
                                            <li><Link to="/">Home</Link></li>

                                            <li className="active">About Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SectionGridLines />
                        <MissionContainer />
                        {/* <FunFacts className='funfacts bg_1' /> */}
                        <div className='py-5'>
                            {/* <ServiceContainer /> */}
                        </div>
                        <TextAbout />
                        <TeamContainer02 />
                        <Testimonials />
                        <FormContainer />
                    </main>
                {/* </>
            )} */}
        </>

    );
};

export default AboutUs;