import React from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionGridLines from '../CommonPages/SectionGridLines';
import { Link } from 'react-router-dom';

const Sliders = () => {
    const SliderCardData = [
        {
            id: '1',
            bgImg: '/images/slider/01.jpg',
            title:'Headquarters ',
            detail:'Lounge area of Shreesh Design Studio LLP'
        },
        {
            id: '2',
            bgImg: '/images/slider/02.jpg',
            title:'Modernized Gurukul',
            detail:'Colorful preschool in Morbi'
        },
        {
            id: '3',
            bgImg: '/images/slider/03.jpg',
            title:'Titan 54',
            detail:'Kashmir feel Living room'
        },
        {
            id: '4',
            bgImg: '/images/slider/04.jpg',
            title:'Jolly N Joy',
            detail:'Resort hotel 3D work'
        },
        {
            id: '5',
            bgImg: '/images/slider/05.jpg',
            title:'Avenue 36',
            detail:'Simple and Sober Bedroom'
        },
        {
            id: '6',
            bgImg: '/images/slider/06.jpg',
            title:'Avenue 36',
            detail:'Monotonous Dining area'
        },
        {
            id: '7',
            bgImg: '/images/slider/07.jpeg',
            title:'La Pinoz',
            detail:'Walk in Dining Restaurant'
        },
        {
            id: '8',
            bgImg: '/images/slider/08.jpg',
            title:'Yamuna Palace',
            detail:'Double heighted Living room'
        },
    ];

    return (
        <div className="theme_slider bg-black">
            <SectionGridLines />
            <div className="container">
                <div className="swiper swiper_theme_slider" data-swiper-autoplay="2000">
                    <Swiper
                        modules={[Pagination, EffectFade, Navigation, Autoplay]}
                        effect="slide"

                        slidesPerView={1}
                        autoplay={{
                            delay: 6000,
                        }}
                        loop={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                    >
                        {
                            SliderCardData.map(data =>
                                <SwiperSlide>
                                    {/* <SliderCard
                                        key={data.id}
                                        data={data}
                                    /> */}
                                    <div className="slider" style={{ backgroundImage: `url(${data.bgImg})` }}>
                                        <div className="slide_content">
                                            <div className="slide_content_wrapper mb-0 h-auto bg-dark-100">
                                                <div className="slide_content_inner">
                                                    <div className="meta m-0">
                                                        <div className="category text-olive text-uppercase">{data.title}</div>
                                                    </div>
                                                    <h4><span className="text-white">{data.detail}</span></h4>
                                                    {/* <div className="details_link">
                                                        <Link to="/project-details">
                                                            <span className="link_text">View Details</span>
                                                            <span className="link_icon">
                                                                <span className="line"></span>
                                                                <span className="circle"></span>
                                                                <span className="dot"></span>
                                                            </span>
                                                        </Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>)
                        }

                    </Swiper>

                    {/* <!-- Add Buttons --> */}
                    <div className="swiper-navigation">
                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Sliders;