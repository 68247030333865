import $ from 'jquery';
import React from 'react';


const FunFacts = (props) => {
    const FunFactData = [

        {
            id: '2',
            contentName: 'Project Completed',
            contentNumber: '500+',
            // plus: '+',
            img: 'images/fact-2.png'
        },
        {
            id: '3',
            contentName: 'Awards Holds in Hand',
            contentNumber: '50+',
            img: 'images/fact-3.png'
        },
        {
            id: '4',
            contentName: 'Years of Experience',
            contentNumber: '25',
            img: 'images/fact-4.png'
        },
    ];

    return (
        <section className={props.className} id="funfacts" style={{ backgroundColor: '#212121', marginTop: '3vmax' }}>
            <div className="container " style={{ padding: '20px 0 20px 0' }}>

                <div className=" ">
                    <div className="row px-2 funfacts_inner">
                        {
                            FunFactData.map(data =>
                                <div className="col-lg-4 col-md-6 ">
                                    <div className="funfact2 d-flex align-items-center p-2">
                                        <div className="fun_img">
                                            <img src={data.img} alt="img" />
                                        </div>
                                        <div className="funfact_content">
                                            <p>{data.contentName}</p>
                                            <div className="d-flex align-items-center"><h2 className="fun-number">{data.contentNumber}</h2> <span>{data.plus}</span></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>

        </section>
    );
};

export default FunFacts;