import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';

const Serenity = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
    return (
        <div>
            
            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Serenity Apartment" activePage="Serenity Apartment" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/01.webp' style={{ height: '40rem' }} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/02.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/03.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/04.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/05.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/06.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/07.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Serenity/08.webp' />
                                        </SwiperSlide>


                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">

                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value"> Dr. Amit Gor</span>
                                                </li>
                                                <li>
                                                    <span className="data">Carpet Area:</span> <span className="value"> 1725 Sq Ft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Serenity Garden, Rajkot</span>
                                                </li>
                                                <li>
                                                    <span className="data">Photography:</span> <span className="value">Manthan Mehta </span>
                                                </li>


                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">

                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Dr. Gor Appartment </h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    The relationship between an interior designer and client is very special. We have come together to transform the client’s wishes and thoughts into reality. This specific house is designed in very modular way to manage the workspace of doctor family. Working on designing, each space is formulated to have least maintenance, however, giving all the aesthetic satisfaction on point. The play of light is a vital factor in designing. It enlightens an emotional value to the space with a darker tone. It's daylighting or artificial mood, light draws a special attention to the fine textures, colors, and forms of a space, helping interiors achieve its true purpose.
                                                </p>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
           
        </div>
    )
}

export default Serenity
