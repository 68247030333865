import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import MyLoader from '../../Shared/MyLoader';
import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const LapinozRaiyachowk = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="La Pinoz, Raiya chowk outlet" activePage="La Pinoz, Raiya chowk outlet" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozRaiyachowk/00.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozRaiyachowk/01.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozRaiyachowk/02.webp' />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/LapinozRaiyachowk/05.webp' />
                            </SwiperSlide>
                         
                            
                            
                            
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value"> Prafulbhai Kathrotia & Nakul Kathrotia  </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value"> 785 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Raiya circle, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value"> Manthan Mehta      </span>
                                    </li>
                                   
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title"> La Pinoz, Raiya chowk outlet </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    A pizzeria is one of the most favourite places of the youth today. Not only is it their favourite food but also a place for fun hangouts for friends. And when it comes to pizzeria La pinoz excites your taste buds like nothing else. This la pinoz pizzeria designed by our team is the 3rd la linoz of the city. It has been crafted by our team to give a strong youthful vibe. Bold and fresh. There is a strong use of colours which add a pinch of fun to the space. There are different textures given in the space by the use of colours and fabrics. A strong brick texture effect followed by a raw and strong concrete effect and translating into the fabrics a simple texture that speaks volumes. There are ceiling hung lights provided at each table that create ambient lighting for the people sitting. The lights will also make your food pictures extraordinary for your IG feed. The seating of the pizzeria has been designed in such a way that it makes an optimal use of the of space. A good 55 people can be accommodated in the space at any given time. The windows have been made into shapes of arches to give a sense of a pizzeria you’ll find in the streets of Europe.

                                   </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
          
</div>
  )
}

export default LapinozRaiyachowk
