import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
import MyLoader from '../../Shared/MyLoader';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Utkarsh = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setLoading(true);
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />;
    }
    return (
        <div>
            {/* {loading ? (
                <Loader />
            ) : ( */}
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="उत्कृष्ट" activePage="उत्कृष्ट" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/Utkarsh/01.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/Utkarsh/02.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/Utkarsh/03.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/Utkarsh/04.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/Utkarsh/05.webp' />
                                    </SwiperSlide>





                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value">Jaybhai Kherasiya</span>
                                            </li>
                                            <li>
                                                <span className="data"> Area:</span> <span className="value">932 Sqyd
                                                </span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">  Morbi, Gujarat</span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title"> उत्कृष्ट</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                Despite being a growing center, Morbi has its roots in Gujarat's history. This old and industrious city presented a challenge of designing something contemporary while being rooted in its heritage. The plot is a collection of four plots, offering enough space for making an elegant garden. The structure is constructed with materials that are readily available in the town thus adding a sustainable touch. To soften the huge mass, the edges have been curved. The curved edges are a contrasting element in the neighborhood and are not traditional. The openings around the house are designed to efficiently use daylight, while the plantation around the house is planned so that, in the near future, it can shade the house from the scorching sun during the summer. The color palette reflects the soil's importance in Morbi due to its economy and prominence in tile manufacturing. The clay bricks, different concrete colors, and green plants are prominently displayed with spring flowers bursting through the landscape.

                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* )} */}
        </div>
    )
}

export default Utkarsh 
