import React from 'react'

const DesignPoint = () => {
  return (
    <div className='container'>

      <div className="row mt-5 designPoint">

        <div className="col-xl-2 col-md-4 col-12 text-center">
          <h5>DYNAMIC</h5>
        </div>
        <div className="col-xl-2 col-md-4 col-12 text-center">
          <h5>PERSPECTIVE</h5>
        </div>
        <div className="col-xl-2 col-md-4 col-12 text-center">
          <h5>MINIMAL</h5>
        </div>
        <div className="col-xl-2 col-md-4 col-12 text-center">
          <h5>MODULAR</h5>
        </div>
        <div className="col-xl-2 col-md-4 col-12 text-center">
          <h5>TRANSFORMING</h5>
        </div>
        <div className="col-xl-2 col-md-4 col-12 text-center">
          <h5>SYMMETRY</h5>
        </div>

      </div>
    </div>
  )
}

export default DesignPoint
