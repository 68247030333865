import React from 'react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

const TeamContainer = () => {
    // const TeamData = [
    //     {
    //         id: '1',
    //         img: 'images/team/13.jpg',
    //         name: 'Haresh Parsana',
    //         education: 'B.E., I.D.',
    //         designation: 'Consultant Interior Designer',
    //         data: "Haresh Parsana is a designer rooted in sincerity, punctuality and honour. An initial career path in civil engineering, he soon realised his calling in designing and obtained a course from Bangalore. Establishing a practice from his own vibrant hard work and skill set, he is not only known for his work but for being a jolly good fellow! His ethical domain has strengthened his personal and professional fortes in life. His inclination towards social works and active participation in the designer’s community undoubtedly makes him a people person, fulfilling the responsibilities that he holds so dear to himself one at a time. "
    //     },
    //     {
    //         id: '2',
    //         img: 'images/team/12.jpg',
    //         name: 'Didhit Parsana',
    //         education: 'B.Design',
    //         designation: 'Consultant Interior Designer',
    //         data: "A tide of different perspective, Dishit resonates to the dialect of simplicity and likes to keep his design executions attached to it. Under the alma mater of Navrachana University, he had always been drawn to the fascinating ability to design living environments. With a keen interest in exploring materials and their technology, creative endeavours are synonymous to pushing boundaries for him. When not immersed in his professional on goings, one will find him exploring the world of chess, sculpting himself to discipline. Although varying in design languages between the principal designers, the very differing set of opinions make Shreesh Design Studio LLP have the bright face of versatility"
    //     },
    // ]
    return (
        <section style={{ display: 'grid', justifyContent: 'center' }}>
            <div className="container">
                <div className="section-header text-center has_line">
                    <h1 className="text-white">Our Team</h1>
                </div>

                <div className="team_inner">
                    <div>


                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5">
                                <img src="images/team/13.jpg" alt="img" />
                            </div>
                            <div className="col-lg-8 col-md-7 mt-1">
                                <h5 className="text-white"><a href="/team-details">Haresh Parsana</a></h5>
                                <p className="text-uppercase m-0 mt-1" style={{ fontSize: '13px' }}>B.E., I.D.</p>
                                <h6 className="text-uppercase text-olive mt-1">Consultant Interior Designer</h6>
                                <p className='mt-1' style={{ fontWeight: 'bold' }}>The man behind the wonder!</p>
                                <p className='mt-1' style={{ textAlign: 'justify', fontSize: '15px' }}>
                                    Haresh Parsana is a designer rooted in sincerity, punctuality and honour. An initial career path in civil engineering, he soon realised his calling in designing and obtained a course from Bangalore. Establishing a practice from his own vibrant hard work and skill set, he is not only known for his work but for being a jolly good fellow! His ethical domain has strengthened his personal and professional fortes in life. His inclination towards social works and active participation in the designer’s community undoubtedly makes him a people person, fulfilling the responsibilities that he holds so dear to himself one at a time.
                                </p>
                            </div>
                        </div>

                        <div className="row align-items-center" style={{ marginTop: '4vmax' }}>
                            <div className="col-lg-8 col-md-7 text-end ">
                                <h5 className="text-white"><a href="/team-details">Dishit Parsana</a></h5>
                                <p className="text-uppercase m-0 mt-1" style={{ fontSize: '13px' }}>B.Design</p>
                                <h6 className="text-uppercase text-olive mt-1">Consultant Interior Designer</h6>
                                <p className='mt-2' style={{ textAlign: 'justify', fontSize: '15px' }}>
                                    A tide of different perspective, Dishit resonates to the dialect of simplicity and likes to keep his design executions attached to it. Under the alma mater of Navrachana University, he had always been drawn to the fascinating ability to design living environments. With a keen interest in exploring materials and their technology, creative endeavours are synonymous to pushing boundaries for him. When not immersed in his professional on goings, one will find him exploring the world of chess, sculpting himself to discipline.
                                    <br />
                                    Although varying in design languages between the principal designers, the very differing set of opinions make Shreesh Design Studio LLP have the bright face of versatility.
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <img src="images/team/12.jpg" alt="img" />
                            </div>
                        </div>
                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamContainer;