import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
import MyLoader from '../../Shared/MyLoader';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Ex_siddhigroupstall = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Siddhi group Stall" activePage="Siddhi group Stall" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_Sidhhigroupstall/01.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                            <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_Sidhhigroupstall/02.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                            <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_Sidhhigroupstall/03.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                            <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_Sidhhigroupstall/04.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                            <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_Sidhhigroupstall/05.webp' /> 
                            </SwiperSlide>
                            <SwiperSlide>
                            <ProjectDetailsSlider img='images/portfolio/details/Commercial/Ex_Sidhhigroupstall/06.webp' /> 
                            </SwiperSlide>
                           
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Siddhi Group
</span>
                                    </li>
                                    <li>
                                        <span className="data"> Stall Size:</span> <span className="value"> 12 mt X 4 mt
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Property Expo and Showcase 2023, Rajkot   </span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value"> Studio Dream  </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Stall Design </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    The Third Stall we designed was for Siddhi Group. For which we also won first runner up position for best designed stall in the medium segment. The requirements were of the display area, waiting area, seating area and discussion area. We tried to give a concept of arches and curves for display areas. Whereas the branding logo was done in a circular form. At the entrance we made an arcade with louvers at the back wall giving the space a great ambience. Also we added some rope lights to add an element. The clients also wanted to display some tiles, which came very last minute to us. We tried to clad tiles on the wall in such a way that adds up to the overall design. The overall experience came out to be very different and attractive towards the public.


                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

</div>
  )
}

export default Ex_siddhigroupstall 
