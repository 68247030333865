import React, { useEffect, useState } from 'react';
// import PageBanner from '../CommonPages/PageBanner';
import AchievementContainer from './AchievementContainer';
// import Loader from '../Shared/Loader';
import { Link } from 'react-router-dom';
import MyLoader from '../Shared/MyLoader';

const Achievement = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>;
    }
    return (
        <>
            {/* {loading ? (
                <Loader />
            ) : (
                <> */}
                    {/* <PageBanner pageTitle='Pages' title="Achievement" activePage="Achievement"></PageBanner> */}
                    <div className="page_header">
                        <div className="page_header_inner achievement_breadcrumb" >
                            <div className="container">
                                <div className="page_header_content d-flex align-items-center justify-content-between">
                                    <h2 className="heading">Achievement</h2>
                                    <ul className="breadcrumb d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>

                                        <li className="active">Achievement</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className="wrapper">
                        <AchievementContainer />
                    </main>
                {/* </>
            )} */}
        </>
    );
};

export default Achievement;