import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Billipatra = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
         
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title=" Bilipatra" activePage=" Bilipatra" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation,Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/01.webp' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/02.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/03.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/04.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/05.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/06.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/07.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/Bilipatra/08.webp' />
                                    </SwiperSlide>

                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value">Mehul Bhai (Hanuman Dyeing)</span>
                                            </li>
                                            <li>
                                                <span className="data">Carpet Area:</span> <span className="value">1725 SqFt</span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value">Rajkot, Gujarat</span>
                                            </li>
                                           

                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title"> Bilipatra</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                            This Ground+ 4 storey building "BilliPatra" is specially designed for our most enthusiast client. The interior design concept was to create a subtle as well as radiant space attributed to the client’s basic requirements. The living, dining, and kitchen comprise of shades of browns and yellow tints to complement each other along with hints of metal in the furniture and artworks to provide a modern ambiance. The glass zula and a leaf patterned mdf cutout pieces in ceiling were deliberately chosen in accordance with the simplicity of the interiors. Pooja unit is designed in such way that it comprises both - Modern and Traditional touch. Stretched ceiling along with profile lights and leaf patterned shape enhancing divine vibes. The lively nature of these spaces attracts the users to be there for a longer time. The spaces are designed in such a way it adapts the users of the space. The bedrooms offers an extended view of the client’s of personalities. Scarcely used shades of various colors in the upholstery compliments the brown veneers. The choice furniture is traditional yet modern giving a cozy look overall. We have utilized the space to maximize individual bedrooms as well as creating multi-purpose collective spaces. A personal Home-theater is also provided with a strips pattern along with brown acoustics for a big group of family or friends visiting either to celebrate an occasion or holidaying together, Spaces were designed keeping in mind various group activities.
                                            </p>
                                            <p>
                                            Conclusion : It’s our passion to express clients’ stories as interiors. which allows us to make it personal & passionate interiors involving them.
                                            </p>
                                           


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
           
        </div>
  )
}

export default Billipatra
