import React from 'react';
// import ServiceCard02 from '../../components/ServiceCard02';
import { Link } from 'react-router-dom';
import SectionGridLines from '../CommonPages/SectionGridLines';

const ServiceContainer = () => {

    const serviceData = [
        {
            id: '1',
            img01: 'images/services/bunglow.jpg',
            img02: 'images/icon_box/1.png',
            title: 'Residential Bunglow',
            text: 'Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture.'
        },
        {
            id: '2',
            img01: 'images/services/apartment.jpg',
            img02: 'images/icon_box/6.png',
            title: 'Residential Apartment',
            text: 'Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture.'
        },
        {
            id: '3',
            img01: 'images/services/hospitality.jpg',
            img02: 'images/icon_box/3.png',
            title: 'Hospitality Projects',
            text: 'Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture.'
        },
        {
            id: '4',
            img01: 'images/services/commercial.jpg',
            img02: 'images/icon_box/4.png',
            title: 'Commercial',
            text: 'Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture.'
        },
        {
            id: '5',
            img01: 'images/services/3Dwork.jpg',
            img02: 'images/icon_box/5.png',
            title: '3D Work',
            text: 'Shreesh Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture.'
        },
    ]
    return (
        <main className="wrapper" >
            {/* <SectionGridLines /> */}
            <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center mt-0">Services</h2>
            </div>
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row justify-content-center">
                        {
                            serviceData.map(data =>
                                <div className="col-lg-4 col-md-6 ">
                                    <div className="icon_box type-2" style={{ position: 'relative', height: "220px" }}>
                                        <img src={data.img01} alt="img" className="post-bg" style={{ height: "100%", width: '100%' }} />

                                        <div className="icon_box_inner">
                                            <img src={data.img02} alt="Icon Box" />
                                            <h4 className="text-white"><span>{data.title}</span></h4>
                                            {/* <p className="text-gray-600">{data.text}</p>
                                            <div className="arrow_effect">
                                                <Link to="/service-details"><span className="crossline1"></span>
                                                    <span className="crossline2"></span></Link>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>

        </main>
    );
};

export default ServiceContainer;