import React from 'react'
import "./miniheader.css"

const Miniheader = () => {
    return (
        <div>
            <div className="container d-lg-block d-none mainminiheader">
                <div className='d-lg-flex flex-wrap gap-5 text-light justify-content-end '>
                    <li className='miniheader'><a href="mailto:shreeshdesignstudio@gmail.com"><i class="bi bi-envelope-fill"></i>&nbsp;&nbsp; shreeshdesignstudio@gmail.com</a></li>
                    <li className='miniheader'><a href="tel:9723517131" ><i class="bi bi-telephone-fill"></i>&nbsp;&nbsp; Office no. +91 9723517131</a></li>
                    <li className='miniheader'><a href="tel:9825217131" ><i class="bi bi-telephone-fill"></i>&nbsp;&nbsp; Mobile No. +91 9825217131</a></li>
                </div>
            </div>
        </div>
    )
}

export default Miniheader
