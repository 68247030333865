import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionGridLines from './SectionGridLines';
// import TestimonialSlide from '../../components/TestimonialSlide';

const Testimonials = () => {

    return (
        <section className="testimonial box_padding pb-0">
            <SectionGridLines />
            <div className="has_line_lg"></div>

            <div className="testimonial_inner bg-black">
                <div className="swiper swiper_testimonial px-lg-4">

                    <Swiper
                        modules={[Pagination, Autoplay]}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                            speed: 3000
                        }}
                        // slidesPerView={1.5}
                        loop={true}
                        spaceBetween={150}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                    >
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Working with Shreesh was a great experience! Shreesh combined my ideas and vision with their expertise and experience, to come up with a beautifully designed space. Shreesh considered not only what the space was going to look like, but also how it was going to be used."</p>
                                <h6 className="text-olive">Patel Infrastructure</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Wonderful people, from design to project management, all went according to plan and to budget. We loved that they listened to our at the time pretty scarce ideas, and presented various options to suit. "</p>
                                <h6 className="text-olive">City Dental Hospital</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Working with Shreesh has been a pleasure and a positive experience. The design team went above and beyond their call of duty. We ended up with an environment that is beautiful, functional and very unique."</p>
                                <h6 className="text-olive">Sega Tiles</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Very beautiful design work, I really love the intricacies that you bring so subtly. Keep up the good work and all the best for the future."</p>
                                <h6 className="text-olive">Back Bone</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Thank you for all of your fantastic design work!! I realize the project would not have gone as smoothly without all of your extra supervision and guidance."</p>
                                <h6 className="text-olive">Top Land</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Shreesh has performed and delivered for us in an efficient and professional fashion. The design talent is exceptional and has given us a unique environment. It’s been a great all around experience."</p>
                                <h6 className="text-olive">Amul Industry</h6>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-block text-center">
                                <p>“Your help and choices were truly impressive, and I would highly recommend your service to anyone who asks me.

                                    Thank you for introducing us to some great interior designers."</p>
                                <h6 className="text-olive">Adico Industry</h6>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;