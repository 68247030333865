import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Asopalav = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
       
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Asopalav" activePage="Asopalav" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav1.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav6.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav7.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav8.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Apartment/Asopalav/asopalav9.webp' />
                            </SwiperSlide>
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Asopalav Enigma</span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">960 Sq Ft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Asopalav enigma, Rajkot</span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Sample house with 100% Swadeshi Element</h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                   This project is a showflat of builder's. It is more focused on making the compact area more spacious. A true design is when you transform small spaces into aesthetically eye grabbing and functionality trates to enhance the entire place and attracts livelihood. This is the perfect example to showcase how the compact area could be converted and organize it for a better place of accommodation.<br></br>

The use of vibrant colours is a vital factor when you have all kind of age group in the family. The blues, yellows and tinge of pink colour used in the rooms and living area have made the design very lively and has enlighten the entire space. Therefore, this colours are used to make the clients mind composed and relaxed. It is very important to consider the aesthetic values of designed elements of each house that gives the pleasure and composure.<br></br>

Swadeshi has become India's today's agenda. We have tried to use all the Indian materials in making the furniture and Artifact's too. The usage of local art and craft has enhanced the beauty of the interiors.<br></br>

The beauty of home resides in each and every inch of it's corner, be it the walls, the furniture or the ceilings. Here are the glimpses of our project.


                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
            
</div>
  )
}

export default Asopalav
