import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';

const TheRegalRegreat = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>
           
            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="The Regal Rretreat" activePage="The Regal Rretreat" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/1.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/2.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/3.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/4.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/5.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/6.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/7.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/8.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/9.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/RegalRetreat/10.webp' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">

                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value">Bhupat bhai</span>
                                                </li>
                                                <li>
                                                    <span className="data">Plot Area: </span> <span className="value">215 Sqyd</span>
                                                </li>
                                                <li>
                                                    <span className="data">Carpet Area:</span> <span className="value">5400 Sqft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location::</span> <span className="value">Rajkot, Gujarat </span>
                                                </li>
                                                <li>
                                                    <span className="data">Photography:</span> <span className="value">Sudhir Parmar </span>
                                                </li>
                                                <li>
                                                    <span className="data">Text:</span> <span className="value">Krisha Patel </span>
                                                </li>

                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">

                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">The Regal Rretreat</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    Tucked into a quaint neighbourhood in Rajkot, this private residence makes a compelling case
                                                    for a robust design language, unafraid to pose as an anomaly within the typical streetscapes of
                                                    the city. Inspired by the Modern Luxury Interiors this project utilises a minimalistic yet edgy
                                                    approach to incorporate a sense of luxury and sophistication in the spaces along with providing a
                                                    level of comfort and functionality to its occupants.
                                                </p>
                                                <p>
                                                    &quot;The Regal Retreat&quot; is an innovative play between forms and materials, filled with aesthetically
                                                    pleasing artworks that embraces its cultural roots with a contemporary perspective. The primary
                                                    focus was to create a naturally flowing layout that integrates the daily activities and functions with
                                                    utmost luxury as the client envisioned. The opulent material palette used in the project was
                                                    carefully selected to create a sense of warm and inviting atmosphere while incorporating cultural
                                                    elements with a modern touch. The variety of veneer, Italian marble, glass and leather used with
                                                    bold accent colour pops and a subtle play of forms played a major role in enhancing the beauty
                                                    of the space by creating a harmonious blend of textures and colours.
                                                </p>
                                              



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            
        </div>
    )
}

export default TheRegalRegreat
