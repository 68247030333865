import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
import ProjectDetailsSlider from '../ProjectDetailsSlider';
// import Loader from '../../Shared/Loader';
import MyLoader from '../../Shared/MyLoader';

const JollyEnjoy3D = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setLoading(true);
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>;
    }
    return (
        <div>
            {/* {loading ? (
                <Loader />
            ) : ( */}
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Jolly En’Joy Resort" activePage="Jolly En’Joy Resort" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/01.webp'/>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/02.webp' />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/03.webp' />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/04.webp' />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/05.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/06.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/07.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/08.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/09.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/10.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/11.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/12.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/13.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/14.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/15.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/16.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/3D-Work/JollyEnjoy3D/17.webp' />
                                        </SwiperSlide>





                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">

                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value">Jolly En’Joy group, Chotila</span>
                                                </li>
                                                <li>
                                                    <span className="data"> Area:</span> <span className="value">10 Acre
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">  Chotila, Gujarat</span>
                                                </li>
                                                <li>
                                                    <span className="data">Text:</span> <span className="value">  Jhanvi Patel </span>
                                                </li>
                                                <li>
                                                    <span className="data">Spaces Designed:</span> <span className="value"> Highway food mall, Theatre,  Party lawn,  Hotel,  Water park, Bungas</span>

                                                </li>


                                            </ul>


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">

                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title"> Jolly En’Joy Resort, Chotila</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    From the base scratch of Architecture to final finishing of interiors, Jolly En’Joy resort was a substantial and wide-reaching project in chotila region, which is a striking vision to all today. Entire conceptual design was bought together by enhancing each space functionality and keeping financial estimate in mind. The property consists of a highway touching food mall leading to a theatre, party lawn, hotel : Lords, Bungas and a curvilinear water park. The main idea was to embed the project in the natural surroundings of vegetation. Each building designed with distinctive, and a contemporary approach captivates the viewer. Moreover, the use of earthy color, throughout the property compliments the environment around. The interior follows the similar color palate with a pop-up furniture enhancing the entire look, bonding it together with warm lights and hanging chandelier.
                                                </p>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            {/* )} */}
        </div>
    )
}

export default JollyEnjoy3D 
