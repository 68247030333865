import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const InvestmentOptions = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
        
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="InvestmentOptions" activePage="InvestmentOptions" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/0.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/1.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/6.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/InvestmentOptions/7.webp' />
                            </SwiperSlide>
                        
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value">Harshadbhai Ashar </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">1490 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value">  Novem Square, Rajkott</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">  Smit Mehta </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Investment Options </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    In the recent interior design project for an Investment company's office, our primary objective was to craft a contemporary and open workspace, characterized by transparent glass partitions fostering visibility across the entire office. While adhering to the client's desire for openness, we strategically implemented partial privacy in specific areas. The glass partitions were not just functional but also adding sophistication to the overall design. Embracing a modern aesthetic throughout the space, we introduced a captivating element – a glass swing suspended on a leather belt, acting as the X-factor, injecting a sense of uniqueness into the environment. Metal elements were thoughtfully incorporated to maintain a sleek and polished look, evident in the partitions, furniture, and a sleek bookshelf in the reception area. The furniture was meticulously designed to integrate green pockets, fostering a connection to nature within the workspace. The culmination of these design elements results in a calm and liberating work environment, where transparency coexists harmoniously with private workspaces, creating an atmosphere conducive to productivity and well-being.


                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
            
</div>
  )
}

export default InvestmentOptions 
