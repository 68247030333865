import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Headquaters = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
  return (
    <div>
          
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Headquaters" activePage="Headquaters" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/0.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/2.webp' />
                            </SwiperSlide>
                            {/* <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/3.webp' />
                            </SwiperSlide> */}
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/4.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/5.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/6.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/7.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/1.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/8.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/9.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/10.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/11.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/12.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/13.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/14.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/15.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Commercial/Headquaters/16.webp' />
                            </SwiperSlide>
                           
                            
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value"> Haresh Parsana & Dishit Parsana </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value">2500 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value">  Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">  Murtaza Gandhi </span>
                                    </li>
                                    <li>
                                        <span className="data">Text</span> <span className="value">  Nishal Sevak  </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Headquarters</h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                   
Shreesh Design Studio is an interior designing firm located in Rajkot, Gujarat. Leading by a father-son duo, the practice recently expanded its physical domain. The intent behind it is not only to accommodate and provide a productive working space for their expanding team but to exhibit, rather proudly, the dual design approaches that can be gracefully aced by the firm. One of the aspects that the principal designers, Haresh and Dishit Parsana, take pride in is their contradicting design thinking and their way of executing the same. One maestro conducts himself through styles that are absolutely plush and sophisticated, reaching the heights of styles that are comparatively extroverted. The other established name is indulging in spaces that are personal and expressive through minimal mediums and sit well with change in time. Together, this brainchild of Shreesh Design Studio is a homage to differing beliefs that soundly exist with each other.<br></br><br></br>
Spread across three storeys, the office space is a confluence of private cabins, working stations, meeting areas, conference rooms, and inserts to rewind and reconnect. Stationed from level four to sixth, one starts to see a hint of everything that the studio stands do, works for, and strives for. As one transcends from one step to another to reach the door, the walls have a visual narrative of the strong foundation on which Shreesh Design Studio is laid.


                                    </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
          
</div>
  )
}

export default Headquaters 
