import React from 'react';

const AsideInfo = () => {
    return (
        <>
            <div className="aside_info_wrapper pb-0">
                <button className="aside_close"><i className="bi bi-x-lg"></i></button>
                <div className="aside_logo">
                    <a href="/" className="light_logo"><img src="images/shreesh-logo.png" alt="logo" style={{ filter: 'invert(100%)', WebkitFilter: 'invert(100%)' }} /></a>
                    <a href="/" className="dark_logo"><img src="images/shreesh-logo.png" alt="logo" /></a>
                </div >
                <div className="aside_info_inner z-50">
                    <p>Shreesh is built on truthful efforts and an attitude of driving their vision to something powerful, every day.</p>

                    <div className="aside_info_inner_box">
                        <h5>Office Address</h5>
                        <p>"Shreesh" 4th - 5th Floor, Dr.Dastur Marg, Off Dr.Yagnik Road, B/h D.H. College, Rajkot - 360001, Gujrat, INDIA</p>

                        <h5>Contact Info</h5>
                        <p><a href="tel:7802017131">Phone No : 4th Floor - +91 7802017131</a></p>
                        <p><a href="tel:9723517131">Phone No : 5th Floor - +91 9723517131</a></p>

                        <p><a href="mailto:shreeshdesignstudio@gmail.com">Email : shreeshdesignstudio@gmail.com</a></p>

                        <h5>Our Ventures</h5>
                        <p><a href="https://shreeshlockers.com/" target='_blank'><img src="images/locker-logo.png" alt="logo" style={{ maxWidth: '160px' }} /></a></p>
                    </div>
                    <div className="social_sites">
                        <ul className="d-flex align-items-center justify-content-center">
                            <li><a href='https://www.facebook.com/shreeshdesignstudiollp/' target='_blank'><i className="bi bi-facebook"></i></a></li>
                            {/* <li><a><i className="bi bi-twitter"></i></a></li> */}
                            <li><a href='https://www.instagram.com/shreeshdesignstudiollp/' target='_blank'><i className="bi bi-instagram"></i></a></li>
                            {/* <li><a><i className="bi bi-youtube"></i></a></li> */}
                        </ul>
                    </div>
                </div>
                <div className='d-flex justify-content-center' >
                    <img src="images/shreesh-short-waves.png" alt="" style={{ filter: 'invert(30%)', WebkitFilter: 'invert(30%)', maxWidth: '200px', opacity: "0.7" }} />
                </div>
            </div >
        </>
    );
};

export default AsideInfo;