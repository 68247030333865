import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation,Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import MyLoader from '../../Shared/MyLoader';
import ProjectDetailsSlider from '../ProjectDetailsSlider';

import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const OyeAnna = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader/>
    }
  return (
    <div>
       
    <main className="wrapper">
        <SectionGridLines />
        <Outlet />
        <PageBanner pageTitle='Projects' subpath='/projects' title="Oye Anna" activePage="Oye Anna" />
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation,Autoplay]}
                            effect="slide"
                            slidesPerView={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/OyeAnna/oyeanna1.webp' style={{ height: '40rem' }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/OyeAnna/oyeanna2.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/OyeAnna/oyeanna3.webp' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ProjectDetailsSlider img='images/portfolio/details/Hospitality-Projects/OyeAnna/oyeanna4.webp' />
                            </SwiperSlide>
                          
                           
                         
                            

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">

                        <div className="sidebar">
                            <div className="project-information">
                                <h6 className="widget-title">
                                    Project Information
                                    <span className="title-line"></span>
                                </h6>
                                <ul>
                                    <li>
                                        <span className="data">Client:</span> <span className="value"> Nakul Kathrotia </span>
                                    </li>
                                    <li>
                                        <span className="data"> Area:</span> <span className="value"> 720 Sqft
</span>
                                    </li>
                                    <li>
                                        <span className="data">Location:</span> <span className="value"> Rajkot, Gujarat</span>
                                    </li>
                                    <li>
                                        <span className="data">Photography:</span> <span className="value">  Smit Mehta  </span>
                                    </li>
                                  

                                </ul>


                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8">

                        <div className="project-details_inner">
                            <div className="post_content">
                                <div className="post-header">
                                    <h3 className="post-title">Oye Anna  </h3>
                                </div>
                                <div className="fulltext">
                                    <p>
                                    A unique fusion of Southern and Northern influences in this restaurant's design'. The vibrant atmosphere is thoughtfully curated through the use of striking colors in the wall arts, creating a dynamic visual tapestry that encapsulates the diverse essence of the country.<br></br><br></br>

The incorporation of a green element in the light installations not only illuminates the space but also infuses a natural and refreshing ambiance. The Moroccan tile flooring, with its intricate patterns, serves as a captivating foundation, adding a touch of exotic elegance to the restaurant's interior.<br></br><br></br>

The choice of white oak for the furniture not only speaks to quality and durability but also imparts a sense of sophistication to the space. The deliberate use of beige seating further enhances the overall aesthetic, offering a warm and inviting setting for patrons.<br></br><br></br>

In this design, every element is carefully chosen and harmoniously arranged to create a dining environment that is not only visually stimulating but also evokes a sense of cultural richness and refined elegance.

                                   </p>
                                 
        

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
          
</div>
  )
}

export default OyeAnna
